import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/feetransportcharge/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/feetransportcharge/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/feetransportcharge/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/feetransportcharge/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/feetransportcharge/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    status(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/feetransportcharge/status', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    updateCharge(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/feetransportcharge/updateCharge', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    updateStatusByIds(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/feetransportcharge/updateStatusByIds', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportExcel(ctx,params) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/feetransportcharge/exportExcel',
          method: 'get',
          params: params,
          responseType: 'blob',
        }).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    mergeSelected(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/feetransportcharge/mergeSelected', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    checkSelected(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/feetransportcharge/checkSelected', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
